<template>
	<div
		class="d-wrap"
		v-click-outside="onClose"
		ref="content"
	>
		<div class="d-wrap__content-wrapper" @click="onToggle">
			<slot name="preview"/>
		</div>

		<div
			v-if="isVisible && !disabled"
			:class="['dropdown', { isFullWidth }]"
			:style="getDropdownStyles"
		>
			<!-- <div class="dropdown__triangle" :style="getDropdownTriangleStyles" /> -->
			<slot name="content"/>
		</div>
	</div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
	name: "BaseDropdown",
	/**
	 * @property {Array} items - массив объектов { value: '', icon: '' }
	 */
	props: {
		items: {
			type: Array,
			default: () => []
		},
		position: {
			type: String,
			default: 'left',
			validator: (value) => ['right', 'left'].includes(value)
		},
		positionValue: {
			type: String,
			default: '20px'
		},
		disabled: {
			type: Boolean,
			default: false
		},
		padding: {
			type: Number,
			default: 10
		},
        isFixed: {
            type: Boolean,
            default: false
        },
        isFullWidth: {
            type: Boolean,
            default: false
        },
        isVirtualScroll: {
            type: Boolean,
            default: false
        },
        spacerHeight: {
            type: Number,
            default: 0
        },
        styleList: {
            type: Object,
            default: () => {},
        },
		widthCustom: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			isVisible: false,
            dropdownStyleFixed: {},
            dropdownTriangleStyleFixed: {'top': '-16px'}
		}
	},
	computed: {
		getDropdownTriangleStyles() {
			return {
				[this.position]: this.positionValue,
                ...this.dropdownTriangleStyleFixed
			}
		},
		getDropdownStyles() {
            let dropdownStyles = {
                padding: this.padding + 'px',
				[this.position]: 0,
				top: this.$refs.content.offsetHeight + 8 + 'px'
            };
			if (this.widthCustom !== '')
				dropdownStyles.width = this.widthCustom;
            const dropdownStyleFixed = this.isFixed ? this.dropdownStyleFixed : {};
			return {...dropdownStyles, ...dropdownStyleFixed, ...this.styleList };
		}
	},
	created() {
		this.$parent.$on('$closeDropdown', this.onClose)
		this.$parent.$on('$openDropdown', this.onOpen)
	},
	methods: {
        calculateDropdownStyleFixed() {
            const {content = {}} = this.$refs;
            const bounding = content.getBoundingClientRect() || {};
            const {bottom = 0, top = 0} = bounding;
            const position = bounding[this.position];
            const heightWindow = window.innerHeight;
            const widthWindow =  window.innerWidth;
            const isDropdownBottom = (heightWindow - bottom - 32) > 270;
            const dropdownPosition = this.position === 'left' ? position : widthWindow - position;
            const dropdownTop = isDropdownBottom ? bottom + 16 : '';
            const dropdownBottom = isDropdownBottom ? '0' : heightWindow - top + 16;
            const dropdownHeight = isDropdownBottom ? (heightWindow - dropdownTop - 16) : (top - 16);
            this.dropdownStyleFixed = {
                'position': 'fixed',
                'top': `${dropdownTop}px`,
                'bottom': `${dropdownBottom}px`,
                [this.position]: `${dropdownPosition}px`,
                'height': (this.spacerHeight < dropdownHeight && this.isVirtualScroll) ? `${this.spacerHeight}px` : `${dropdownHeight}px`,
                'max-height': '350px',
                // 'width': this.isVirtualScroll ? '300px' : ''
            };

            this.dropdownTriangleStyleFixed = isDropdownBottom ? {'top': '-16px'} : {'bottom': '-16px', 'transform': 'rotate(180deg)'};
        },
		/**
		 * выбрал пункт из dropdown
		 */
		onCheck(value) {
			this.$emit('check', value)
			this.onClose()
		},
		/**
		 * Закрыть dropdown
		 */
		onClose() {
			this.isVisible = false
		},
		/**
		 * Открыть dropdown
		 */
		onOpen() {
			if (this.disabled) { return }

			this.isVisible = true
		},
		onToggle() {
			if (this.disabled) { return }

			this.isVisible = !this.isVisible
		}
	},
	directives: {
		ClickOutside
	},
	watch: {
		isVisible(newValue) {
			this.$emit('changeVisible', newValue)
            if (this.isFixed)
                this.calculateDropdownStyleFixed();
		}
	}
}
</script>

<style lang="scss" scoped>
.d-wrap {
	position: relative;

    &__content-wrapper {
        height: 100%;
    }
}

.dropdown {
	cursor: initial;
	position: absolute;
	background: #fff;
    white-space: nowrap;
    z-index: 10;
	box-shadow: 0 3px 15px #21242D17;
	// border-radius: 10px;

	border: 1px solid #F4F5F5;
	border-radius: 10px;
    // width: 100%;

    // border-radius: 4px;
	// box-shadow: 0 4px 28px rgba(59, 74, 116, .15), 0 4px 10px rgba(59, 74, 116, .15);

    &.isFullWidth {
        width: 100%;
    }

	&__triangle {
		content: "";
		position: absolute;
		display: block;
		border: 9px solid transparent;
		border-bottom-color: #fff;
		// top: -16px;
		z-index: 9;
	}
}
</style>
