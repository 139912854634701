<template>
    <div class="layout">
        <div class="layout__main">
            <Cap
                v-if="!isShare"
                class="layout__cap"
                :title="$route.meta.title || 'Oohdesk'"
                :logo-src="capLogoSrc"
                :logoSize="capLogoSize"
                view="transparent"
                :config="capConfig"
                :user-menu-data="profile"
                :historyItems="storedHistoryItems"
                :isShowAppMenu="isMenuOpen"
                :menuItems="menuItems"
                :zIndexStep="folderQueue.length"
                @onHamburger="onHamburger"
                @onHistoryDropdownChangeVisible="historyDropdownChangeVisible"
                @onFilter="onFilter"
                @onUserMenuLogout="logout"
            >
                <template v-slot:filterPanel>
                    <FilterPanel
                        :chips="chips"
                        :cities="citiesList"
                        :selectedCities="selectedCities"
                        :accessDates="accessDates"
                        :checkedDates="checkedDates"
                        :isFilterOpen="isFilterOpen"
                        :isLoading="isFilterLoading"
                        @toggle="onFilter"
                        @removeChips="onRemoveChips"
                        @checkCity="onCheckCity"
                        @approveDatepicker="onApproveDatepicker"
                        @clear="onResetFilter"
                    />
                </template>
            </Cap>
            <div
                class="layout__content"
                :style="layoutContentStyle"
            >

                <AppFilter
                    v-if="isFilterOpen"
                    :params="filterDataParams"
                    :selected="filterDataSelected"
                    :isLoading="isFilterLoading"
                    :panelData="filterPanelData"
                    :sidesNumber="filterSidesNumber"
                    :style="stylesFilter"
                    class="layout__filter"
                    @check="onCheckFilter"
                    @transfer="onTransfer"
                    @close="onFilter"
                    @clear="onResetFilter"
                />
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapMutations, mapGetters} from 'vuex';
import AppFilter from '@/components/Filter/Filter'
import FilterPanel from './components/FilterPanel'
import ServiceApi from '../services/ServiceApi';
import ServiceUser from "@/services/ServicesAuth/ServiceUser";
import ServiceAuth from "@/services/ServicesAuth/ServiceAuth";
import MixinApi from "@/mixins/MixinsPageBayuerdesk/MixinApi";
import MixiHistory from "@/mixins/MixiHistory";
import lodash from "lodash";
export default {
    name: 'DefaultLayout',
    components: {
        AppFilter,
        FilterPanel,
    },
    mixins: [
        MixinApi,
        MixiHistory
    ],
    data() {
        return {
            isMenuOpen: false,
            isFilterOpen: false,
            citiesList: [],
            profile: {},

            filterDataParams: [],
            filterDataSelected: {},
            filterDataAccessDates: [],
            isFilterLoading: false,
            filterSidesNumber: 0,
            withoutPricesStepCountData: 0,
            withoutPricesSideCountData: 0,
            filterPanelData: [
				{ value: 'through', title: 'Сквозное размещение' },
				{ value: 'without_prices', title: 'Стороны без цен' },
                { value: 'is_full_period', title: 'Стороны на целый месяц' }
			],
            menuItems: [
                {
                    style: {
                        backgroundColor: '#f8c7dd'
                    },
                    title: 'Buyerdesk',
                    links: [
                        {
                            label: 'Подбор предложений',
                            path: '/buyerdesk'
                        },
                        {
                            label: 'Наборы',
                            path: '/reconciliation/kits'
                        },
                        {
                            label: 'Отправленые предложения',
                            path: '/reconciliation/offers'
                        },
                        {
                            label: 'Предфакт',
                            path: '/reconciliation/approved'
                        },
                        {
                            label: 'Факт',
                            path: '/reconciliation/fact'
                        },
                    ]
                },
            ],
            capLogoSize: {
                width: '150',
                height: '100'
            }
        }
    },

    mounted() {
        const {currentRoute = {}} = this.$router;
        const {name = ''} = currentRoute;
        if (name === 'bayuerdeskV2' || name === 'price')
            this.profile = ServiceUser.profile;
    },

    computed: {
        ...mapState({
            isFilterUpdated: state => state.filter.isFilterUpdated,
            currentComponent: state => state.currentComponent,
            storedHistoryItems: (state) => {
                return state.history.historyItems.sort((A, B) => {
                    const {id: idA = -1} = A;
                    const {id: idB = -1} = B;
                    return parseInt(idB) - parseInt(idA);
                })
            }
        }),
        ...mapGetters('folders', ['folderQueue']),
        stylesFilter() {
            return { zIndex: 6 + this.folderQueue.length }
        },

        isShare() {
			const {query = {}} = this.$route;
			const {shareToken = null} = query;
			return shareToken !== null;
		},
        layoutContentStyle() {
            const layoutContentStyle = this.currentComponent === 'PageBuyerdesk' ? {'padding': '0px'} : {};
            return layoutContentStyle;
        },
        /**
         * Возвращает выделенные элементы
         * @returns {Array}
         */
        chips() {
            const result = []
            const selectedData = this.filterDataSelected
            const params = this.filterDataParams ?? []

            /* записывает в result, если есть выделенное значение */
            for (let propName in selectedData) {
                if (
                    Array.isArray(selectedData[propName]) &&
                    selectedData[propName].length > 0
                ) {
                    // TODO
                    if (propName === 'date') { continue }

                    const selectedValues = selectedData[propName]

                    selectedValues.forEach(id => {
                        const filterItem = params.find(item => item.id === propName)

                        if (filterItem) {
                            const optionItem = filterItem?.items.find(i => i.id === id)
                            if(optionItem !== undefined){
                                const { value } = optionItem
                                result.push({ propName, value, id })
                            }
                        }
                    })
                }
            }

            return result
        },
        selectedCities() {
            let result = this.filterDataSelected['city_id'] || []
            result = result.map(i => String(i))
            return result
        },
        withoutPricesSideCount: {
            get() {
                return this.withoutPricesSideCountData
            },
            set(value) {
                this.withoutPricesSideCountData = value
            }
        },
        withoutPricesStepCount: {
            get() {
                return this.withoutPricesStepCountData
            },
            set(value) {
                this.withoutPricesStepCountData = value
            }
        },
        accessDates() {
            const result = []
            const dates = this.filterDataAccessDates || []

            dates.forEach(item => {
                const YY_MM_DD = item.split('-')
                const year = Number(YY_MM_DD[0])
                const month = Number(YY_MM_DD[1])

                result.push({ year, month })
            })

            return result
        },
        checkedDates() {
            const result = []
            const dates = this.filterDataSelected?.date || []

            dates.forEach(item => {
                const YY_MM_DD = item.split('-')
                const year = Number(YY_MM_DD[0])
                const month = Number(YY_MM_DD[1])

                result.push({ year, month })
            })

            return result
        },

        capConfig() {
            return {
                isConfigHamburger: false,
                isConfigStatus: false,
                isConfigNotification: false,
                isConfigSettings: true,
            };
        },

        capLogoSrc() {
            // return require('@/assets/images/logo-sv-min.svg');
            return require('@/assets/images/OohdeskBUYER.svg');
        }
    },
    watch: {
        filterDataSelected: function (filterDataSelected) {
            this.setFilterSelectedData(filterDataSelected);
        },
        citiesList: function (citiesList){
            this.setCityList(citiesList);
        },
        isFilterUpdated: function (isUpdated) {
            if(isUpdated){
                this.fetchFilterData(this.filterDataSelected);
            }
            this.setIsFilterUpdated(false);
        },
        withoutPricesStepCount: function (withoutPricesStepCount) {
            this.setWithoutPricesStepCount(withoutPricesStepCount);
        },
        withoutPricesSideCount: function (withoutPricesSideCount) {
            this.setWithoutPricesSideCount(withoutPricesSideCount);
        },
        selectedCities(value = '') {
            const citiesIds = this.citiesList.map(city => String(city.id));
            if (!citiesIds.includes(String(value)))
                this.onResetFilter();
        }
    },
    async created() {
        this.isFilterLoading = true
        if(!this.isFilterOpen){
            this.setIsFilterLoading(true);
        }

        if(!this.isShare) {
            await this.fetchCities()
            await this.fetchDates(null, (dates) => {
                this.filterDataAccessDates = dates
            })
            await this.fetchFilterData(null)
        }
    },
    methods: {
        ...mapMutations('filter', [
            'setFilterSelectedData',
            'setWithoutPricesStepCount',
            'setIsFilterLoading',
            'setWithoutPricesSideCount',
            'setIsFilterUpdated',
            'setCityList'
        ]),
        ...mapMutations('history', [
            'setHistoryItems',
        ]),
        ...mapMutations('widgetPoi', [
            'setWidgetPoi',
        ]),
        async fetchFilterData(body = {}) {
            try {
                this.isFilterLoading = true
                if(!this.isFilterOpen){
                    this.setIsFilterLoading(true);
                }
                // let response = await this.$axios.post('https://free.oohdesk.ru/filter/filter/filter-base', {filter_params: body})
                let response = await ServiceApi.post('free', '/filter', {filter_params: body});
                response = response.data.data
                const params = response?.data?.params ?? []
                const selected = response?.selected_data ?? {}

                for (let param of params) {
                    if (param.src) {
                        const response = await this.fetchFilterItemData(param.src)
                        const items = response.data.data
                        param.items = items
                    }
                }
                this.withoutPricesStepCount = response.without_prices_step_count;
                this.withoutPricesSideCount = Number(response.side_count)
                this.filterSidesNumber = Number(response.side_count)
                this.filterDataParams = params
                this.filterDataSelected = selected

            } catch(err) {
                console.log(err);
            } finally {
                this.isFilterLoading = false
                if(!this.isFilterOpen){
                    this.setIsFilterLoading(false);
                }
            }
        },
        async fetchFilterItemData(url) {
            try {
                // return await this.$axios.get('https://free.oohdesk.ru' + url)
                return await ServiceApi.get('free', String(url));
            } catch (err) {
                console.log(err);
            }
        },
        /**
         * @param {Object} body
         * @param {Function} actionAfter
         * @returns {Promise<void>}
         */
        async fetchDates(body = {}, actionAfter = () => {}) {
            try {
                let response = await ServiceApi.post(
                    'free',
                    '/filter-dates',
                    {filter_params: body}
                );
                const dates = response.data.data ?? []

                actionAfter(dates);
            } catch(err) {
                console.log(err);
            }
        },
        async fetchCities() {
            try {
                // let response = await this.$axios.get('https://free.oohdesk.ru/filter/filter/cities')
                let response = await ServiceApi.get('free', '/filter/cities');
                response = response.data.data

                const citiesList = response.map(item => {
                    const {id, name, lat, lng, zoom} = item
                    return {id, value: name, lat: Number(lat), lng: Number(lng), zoom: Number(zoom)}
                })

                this.citiesList = citiesList
            } catch(err) {
                console.log(err);
            }
        },
        onHamburger(isShow) {
            this.isMenuOpen = isShow;
            this.isFilterOpen = false;
        },
        // onHamburger() {

        // },
        onFilter() {
            this.isFilterOpen = !this.isFilterOpen;
            this.isMenuOpen = false;
        },
        onCheckFilter(event) {
            const { filterItemId, value } = event
            this.filterDataSelected[filterItemId] = value

            this.fetchFilterData(this.filterDataSelected)
        },
        onTransfer(filterItemId) {
            const filterItem = this.filterDataParams.find(item => item.id === filterItemId)
            filterItem.hidden = !filterItem.hidden
            this.filterDataParams = this.filterDataParams.slice()
        },
        onResetFilter() {
            this.fetchFilterData({reset: "Y"})
        },
        /**
         * Удаляю тег из шапки
         * @param {Object} - данные об удаляемом тэге
         */
        onRemoveChips(chipsData) {
            const {propName, id} = chipsData
            const index = this.filterDataSelected[propName].findIndex(i => i === id)
            this.filterDataSelected[propName].splice(index, 1)

            this.fetchFilterData(this.filterDataSelected)
        },
        onCheckCity(event) {
            const { filterItemId, value } = event
            const filterSelected  = { [filterItemId]: value }
            this.isFilterLoading = true
            this.fetchDates(filterSelected, (dates) => {
                this.filterDataAccessDates = dates
                this.fetchFilterData(null)
            })
        },
        onApproveDatepicker(event) {
            const result = event.map(period => {
                let month = String(period.month)
                month = month.length == 1 ? `0${month}` : month
                const year = period.year

                return `${year}-${month}-01`
            })

            const isEmptyOrEqual = !result.length || lodash.isEqual(result, this.filterDataSelected?.date ?? [])
            if(isEmptyOrEqual)
                return;

            const body = {
                filterItemId: 'date',
                value: result
            }

            this.onCheckFilter(body)
        },

        async logout() {
            const {currentRoute = {}} = this.$router;
            const {name = ''} = currentRoute;
            if (name === 'bayuerdeskV2' || name === 'price')
                await ServiceAuth.logout();
        },

        historyDropdownChangeVisible(isShow) {
            if(isShow){
                this.postHistory({}, (historyItems) => {
                    this.setHistoryItems(historyItems);
                });
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.layout {
    height: 100vh;
    display: flex;

    .loading {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
    }

    &__main {
        position: relative;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    &__content {
        position: relative;
        // z-index: 100;
        width: 100%;
        height: 100%;
        background: #fff;
        // overflow: hidden;
        padding: 10px 40px 40px 40px;
    }


    &__filter {
        padding: 20px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        z-index: 3;
    }
}
</style>
